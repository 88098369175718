import React from 'react'

function Letter() {
  return (
    <div className='bg-theme page'>
      <div>
        <h1 className='text-center notes-text pt-5'>Stuff I want to say to you</h1>
        <p className='my-text-color px-2'>
        It was really nice hanging out with you over the summer! 
        I think I told you this before, but I really like talking to you. 
        You make me feel heard. That being said, I want to hear more about your life 😀. 
        If you ever think, “wow I'm talking so much”, no you are not. Talk more 😏. We're not really texters are we. 
        But I hope you do know that if you ever need someone to talk to, my doors are always open. 
        And if you don't feel comfortable sharing with me, do know that serena would gladly accept you with open arms. 
        If you ever need some ego boosting, I got you. 
        If sandy or anyone bothers you, let me know, I'll fight them for you, or I can lend an ear to you. 
        Honestly, I don't have much to say. I feel like I said everything I wanted to say to you when we hung out together
        at pacific mall. I really enjoy playing catan with you or hanging out with you in groups. I cannot remember if
        I told you this, but I am worried that you feel excluded so I try my best to include you. I really want you part
        of my group yknow? Also for catan, casey you too nice. You gotta be mean and crush people 😡. You have that advangtage.
        Not that you need it. Or maybe I just want to see you be mean lolll
        </p>
      </div>
    </div>
  )
}

export default Letter;