import React from "react";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";

function Navbar() {

  return (
    <>
      <nav className="navbar my-nav-bar" data-bs-theme="dark">
        <div className="container-fluid">
          <Link to="#" >
            <button className="navbar-toggler my-icon" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
              <i className="bi bi-list my-icon"></i>
            </button>
          </Link>
          <div className="offcanvas offcanvas-start my-nav-bar" tabindex="-1" id="offcanvasNavbar">
            <div class="offcanvas-header">
            <button type="button" className="my-icon" data-bs-dismiss="offcanvas" aria-label="Close">
                <i className="bi bi-x-lg my-icon"></i>
              </button>
            </div>
            <div className="offcanvas-body my-nav-bar-off">
              <ul className="navbar-nav flex-grow-1 ps-2" >
                {SidebarData.map((item, index) => {
                  return (
                    <li key={index} className={item.cName} data-bs-dismiss="offcanvas">
                      <Link to={item.path} className='nav-link'>
                        {item.icon}
                        <span className="ms-4 my-icon">{item.title}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;