import React from "react";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <i className="bi bi-house-door-fill my-icon"></i>,
    cName: "nav-item",
  },
  {
    title: "Songs",
    path: "/songs",
    icon: <i class="bi bi-music-note-beamed my-icon"></i>,
    cName: "nav-item",
  },
  {
    title: "Letter",
    path: "/letter",
    icon: <i class="bi bi-envelope-fill my-icon"></i>,
    cName: "nav-item",
  },
  {
    title: "Other",
    path: "/other",
    icon: <i class="bi bi-question-circle my-icon"></i>,
    cName: "nav-item",
  },
];