import React from 'react';
import Cell from '../components/Cell';
import animal from '../photos/animal.png'
import character from '../photos/character.png';
import colour from '../photos/colour.png';
import crystal from '../photos/crystal.png';
import flower from '../photos/flower.png';
import food from '../photos/food.png';
import hobby from '../photos/hobby.png';
import place from '../photos/place.png';
import season from '../photos/season.png'


function Other() {
  return (
    <div className='bg-theme text-center page'>
      <div className='other-title py-4'>
        <h1>My Impression of You :D</h1>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg'>
            <Cell title='Animal' src={animal}/>
          </div>
          <div className='col-lg'>
            <Cell title='Place' src={place}/>
          </div>
          <div className='col-lg'>
            <Cell title='Flower' src={flower}/>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg'>
            <Cell title='Character' src={character} />
          </div>
          <div className='col-lg'>
            <Cell title='Season' src={season}/>
          </div>
          <div className='col-lg'>
            <Cell title='Hobby' src={hobby}/>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg'>
            <Cell title='Colour' src={colour}/>
          </div>
          <div className='col-lg'>
            <Cell title='Crystal' src={crystal}/>
          </div>
          <div className='col-lg'>
            <Cell title='Food' src={food}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Other;