import React from 'react';
import video from '../videos/Happy Birthday.mp4';

function Home() {
  return (
    <div className='bg-theme text-center page'>
      <div>
        <h1 className='hb-text pt-5'>Happy Birthday!</h1>
        <video className='video-border' src={video} controls></video>
      </div>
    </div>
  );
}

export default Home;