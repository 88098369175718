import React from 'react';
import payphone from '../videos/Payphone.mp4'

function Songs() {
  return (
    <div className='bg-theme text-center page'>
      <div>
        <h1 className='song-text pt-5'>Songs for you!</h1>
        <figure className='figure'>
          <video className='video-border' src={payphone} width='90%' controls></video>
          <figcaption className='figure-caption text-wrap cell my-text-color mx-3 px-3'>
            I have come to accosiate (how to spell??) this song with you. 😅
            I played it on the trombone and I did not practice cuz I thought it would be funny.
            But this is a good song, it makes me sad. 
          </figcaption>
        </figure>
      </div>
    </div>
  );
}

export default Songs;