import React from 'react'

function Cell({ title, src }) {
    return (
        <div className='container-fluid cell m-2'>
            <div className='row'>
                <h3 className='pt-4 cell-text'>{title}</h3>
            </div>
            <div className='row'>
                <img className='p-5' src={src} alt=''>
                </img>
            </div>
        </div>
    )
}

export default Cell